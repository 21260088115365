@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
/* <RESET_CSS> */
html {
  box-sizing: border-box;
  font-size: 100%;
  -webkit-text-size-adjust: 100%; /* For Android, iOS Safari */
  -ms-text-size-adjust: 100%; /* For Internet Explorer */
  text-size-adjust: 100%; /* Standard property */
  border-collapse: collapse;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
 font-family: "Outfit", sans-serif;
}

body {
 font-family: "Outfit", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

input,
button,
textarea,
select {
  font: inherit;
  color: inherit;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

a:focus {
  outline: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: anywhere;
  font-family: "Outfit", sans-serif;
}

ul,
ol {
  list-style: none;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 0.5rem;
}

#root,
#__next {
  isolation: isolate;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  padding: 0;
  line-height: 1.15;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

@media (max-width: 600px) {
  html,
  body {
    font-size: 14px;
  }
}

/* </RESET_CSS> */