/* Custom styling for react-toastify to match webcity.dev aesthetics */

/* Toast container and positioning */
.Toastify__toast-container {
  z-index: 9999;
}

/* Individual toast styling */
.Toastify__toast {
  border-radius: 10px;
  background: #ffffff;
  color: #333333;
  font-family: inherit;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  border-left: 4px solid;
}

/* Success toast */
.Toastify__toast--success {
  border-left-color: #FF1493;
}

/* Error toast */
.Toastify__toast--error {
  border-left-color: #d32f2f;
}

/* Warning toast */
.Toastify__toast--warning {
  border-left-color: #ff9800;
}

/* Info toast */
.Toastify__toast--info {
  border-left-color: #FF69B4;
}

/* Progress bar styling */
.Toastify__progress-bar {
  background: linear-gradient(135deg, #FF1493 0%, #FF69B4 100%);
  height: 3px;
}

/* Toast body - the content area */
.Toastify__toast-body {
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}

/* Close button */
.Toastify__close-button {
  color: #333333;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.Toastify__close-button:hover {
  opacity: 1;
}

/* Animation for toasts */
.Toastify__toast--animate {
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
}
